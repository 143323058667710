import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import store from '../store/index'

Vue.use(VueRouter)

function guard(to, _from, next){
  if(store.state.authenticated == window.token){
    next({name: 'login', query: { redirect: to.fullPath }})
  }
  else{
    next();
  }
}

import TestsVersion from "../components/pages/Shared/TestsVersion.vue"

import Login from '../components/pages/Shared/Login.vue';
import NewArticle from '../components/pages/articles/CreateArticle.vue';
import ListArticle from '../components/pages/articles/ListArticle.vue';
import CreateProduct from '../components/pages/data-products/Create.vue';
import ListProduct from '../components/pages/data-products/ListProduct.vue';
import ListUsers from '../components/pages/Users/ListUsers.vue';
import ListDataBlocs from '../components/pages/data-blocs/ListDataBlocs.vue';
import CreateDataBlocs from '../components/pages/data-blocs/Create.vue';

const routes = [
  { path: '/obsolete', name: 'obsoleteApp', component: TestsVersion},
  
  { path: '/', name: 'login', component: Login },
  { path: '/accueil', name: 'dashboard1', component: Home, beforeEnter: guard },
  { path: '/nouvel-article', name: 'new-article', component: NewArticle, beforeEnter: guard },
  { path: '/liste-article', name: 'list-article', component: ListArticle, beforeEnter: guard },
  { path: '/creation-data-product', name: 'create-product', component: CreateProduct, beforeEnter: guard },
  { path: '/liste-des-data-products', name: 'list-produit', component: ListProduct, beforeEnter: guard },
  { path: '/liste-des-utilisateurs', name: 'list-users', component: ListUsers, beforeEnter: guard },
  { path: '/liste-des-data-bloc', name: 'list-data-bloc', component: ListDataBlocs, beforeEnter: guard },
  { path: '/create-data-bloc', name: 'create-data-bloc', component: CreateDataBlocs, beforeEnter: guard },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active" // active class for *exact* links.
})

export default router
