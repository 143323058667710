<template>
    <div class="wrapper">
      <left-nav />
      <top-nav />
  
      <div class="content-wrapper">
        <jumbottron
          faIcon="fa-solid fa-plus"
          PageTitle="Création des datablocs"
        />
  
        <div class="content">
          <div class="container-fluid">
                <b-card class="card card-primary card-outline">
                    <form @submit.prevent="sendData()">
                        <fieldset class="scheduler-border">
                            <legend class="scheduler-border">DataBloc</legend>

                            <b-card class="card card-primary card-outline">
                            <div class="form-group">
                                    <label for="categories">Catégories</label>
                                    <b-card>
                                        <div class="row">
                                            <div class="col-sm-4" v-for="category in categoriesList" :key="category.id">
                                                <b-form-checkbox :value="category.id" :id="'category-' + category.id" v-model="categoriesInput" name="check-button" switch>
                                                    {{ category.name }}
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </b-card>

                                    <!-- <select id="categories" v-model="categoriesInput" multiple class="form-control">
                                        <option v-for="category in categoriesList" :key="category.id" :value="category.id">
                                            {{ category.name }}
                                        </option>
                                    </select> -->
                                </div>

                                <div class="row">
                                    <div class="col-sm-8">
                                        <div class="form-group">
                                            <label for="title">Question</label>
                                            <input type="text" class="form-control" id="title" v-model="payload.dataBloc.title"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="default_chart_type">Type de graphique par défaut</label>
                                            <select class="form-control" id="default_chart_type" v-model="payload.dataBloc.default_chart_type">
                                                <option value="" disabled selected>----</option>
                                                <option value="pie">Pie</option>
                                                <option value="line">Line</option>
                                                <option value="doughnut">Doughnut</option>
                                                <option value="bar">Bar</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="form-group">
                                    <label for="status">Statut</label>
                                    <input type="text" disabled class="form-control" id="status" v-model="payload.dataBloc.status"/>
                                </div>

                                <!-- DataBlocValue -->
                                <fieldset class="scheduler-border">
                                    <legend class="scheduler-border">DataBlocValue</legend>

                                    <b-card class="card-primary card-outline" v-for="(value, valueIndex) in payload.dataBlocValues" :key="valueIndex">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label :for="'label-' + valueIndex">Réponse {{ valueIndex+1 }}</label>
                                                    <input type="text" class="form-control" :id="'label-' + valueIndex" v-model="value.label"/>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label :for="'data_value-' + valueIndex">Valeur</label>
                                                    <input type="number" class="form-control" :id="'data_value-' + valueIndex" v-model="value.data_value"/>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card>

                                    <div class="form-group">
                                        <button type="button" @click="addDataBlocValue()" class="btn btn-secondary">
                                            <i class="fa-solid fa-plus"></i> Ajouter une dataBlocValue
                                        </button>

                                        <button type="button" @click="removeDataBlocValue(valueIndex)" class="btn btn-warning float-right">
                                            <i class="fa-solid fa-eraser"></i> Retirer une dataBlocValue
                                        </button>
                                    </div>
                                </fieldset>
                            </b-card>

                        </fieldset>

                    <div class="form-group float-right mt-2">
                        <b-spinner variant="success" v-if="loading" label="Spinning"></b-spinner>
                        <button class="btn text-bold btn-lg" style="background-color: #3BAE4C;color:#FFF" type="submit" v-if="!loading">
                            <i class="fa-solid fa-floppy-disk"></i> Enregistrer le formulaire
                        </button>
                    </div>
                    </form>
                </b-card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </template>
    
    <script>
  import axios from "axios";
  import LeftNav from "../../LeftNav.vue";
  import TopNav from "../../TopNav.vue";
  import Footer from "../../Footer.vue";
  import Jumbottron from "../Shared/Jumbottron.vue";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import Swal from "sweetalert2";
  
  export default {
    components: {
      Jumbottron,
      LeftNav,
      TopNav,
      Footer,
    },
  
    data() {
      return {
        editor: ClassicEditor,
        loading: false,
        categoriesList: [],
  
        payload: {
            dataBloc: {
                title: "",
                categories_ids: "[]",
                default_chart_type: "",
                status: "innactiv",
            },
            dataBlocValues: [
                {
                    label: "",
                    data_value: "",
                    status: "activ"
                },
            ],
        },
        categoriesInput: "",
      };
    },
  
    mounted() {
      this.getCategorie();
    },
  
    methods: {
      async getCategorie() {
        await axios.get("setup").then((res) => {
          this.categoriesList = res.data.activity_areas;
        });
      },
  
      addDataBlocValue() {
          this.payload.dataBlocValues.push({
              label: "",
              data_value: "",
              "status": "active"
          })
      },
  
      removeDataBlocValue(valueIndex){
          this.payload.dataBlocValues.splice(valueIndex, 1)
      },
  
      addDataBloc(item){
          this.payload.push({
              dataBloc: {
                title: "",
                categories_ids: "[]",
                default_chart_type: "",
                status: "innactiv",
                dataBlocValues: [
                  {
                    label: "",
                    data_value: "",
                    status: "active"
                  },
                ],
              },
          })
      }, 
  
      removeDataBloc(index){
          this.payload.splice(index, 1)
      },
  
      async sendData() {
        if (typeof this.categoriesInput === 'string') {
            this.payload.dataBloc.categories_ids = JSON.stringify(this.categoriesInput.split(',').map(id => parseInt(id.trim())));
        }
        this.loading = true;
        let Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
        });
          if(!this.payload.dataBloc.title || !this.payload.dataBloc.default_chart_type){
            Toast.fire({
                icon: "error",
                title: "Tous les champs sont requis",
            });
            this.loading = false;
          }
          else{
            axios.post('databloc', this.payload)
            .then(()=>{
                Toast.fire({
                    icon: "success",
                    title: "Insertion des données effectuées avec succès",
                });
                this.loading = false

                this.$router.push({name: "list-data-bloc"})
            })
            .catch(()=>{
                Toast.fire({
                    icon: "error",
                    title: "Une erreur s'est produite, réessayez svp",
                });
                this.loading = false;
            })
          }
      },
    },
  };
  </script>
  
  <style scoped>

  .content-wrapper{
        font-family: 'Arial', sans-serif;
        font-weight: normal;
    }
      fieldset.scheduler-border{
          border: 2px solid #3A77BC !important;
          padding: 0 10px 10px 10px;
          border-bottom: none;
      }
  
      legend.scheduler-border{
          width: auto !important;
          border:none;
          font-size: 14px;
          float: none;
          padding: inherit;
          color: #3BAE4C;
          font-weight: bold !important;
      }
  </style>