<template>
  <div>
    <aside class="main-sidebar sidebar-dark-primary">
    <router-link :to="{name: 'dashboard1'}" class="brand-link" style="text-decoration: none;background-color: #3A77BC;">
      <img
        src="../assets/logo_data.jpeg"
        alt="DATATRENZ"
        class="brand-image img-thumbnail"
        style=""
      />
      <span class="brand-text font-weight-bold">DATATRENZ</span>
    </router-link>

    <div class="sidebar">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
              src="../assets/user.png"
              class="img-circle elevation-2"
              alt="User Image"
            />
        </div>
        
        <div class="info text-bold" v-if="user">
          <router-link :to="{ name: 'dashboard1' }" class="d-block" style="text-decoration: none">
            <span class="text-capitalize">{{ user.first_name }}</span> {{ user.name.toUpperCase() }} 
          </router-link>
        </div>
      </div>

      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item menu-open">
            <a href="#" class="nav-link" style="background-color: #3FADE2;">
                <i class="fa-solid fa-newspaper"></i>
              <p>
                Gestion d'articles
                <em class="right fas fa-angle-left"></em>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link :to="{name: 'new-article'}" class="nav-link">
                    <i class="fa-solid fa-plus nav-icon"></i>
                  <p>Nouvel article</p>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link :to="{name: 'list-article'}" class="nav-link">
                    <i class="fa-regular fa-newspaper nav-icon"></i>
                  <p>Liste d'articles</p>
                </router-link>
              </li>
            </ul>
          </li>

          <hr/>
          <li class="nav-item menu-open">
            <a href="#" class="nav-link" style="background-color: #3FADE2;">
                <i class="fa-brands fa-product-hunt"></i>
              <p>
                DataProduct
                <em class="right fas fa-angle-left"></em>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link :to="{name: 'create-product'}" class="nav-link">
                    <i class="fa-solid fa-plus nav-icon"></i>
                    <p>Nouveau DataProduct</p>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link :to="{name: 'list-produit'}" class="nav-link">
                    <i class="fa-solid fa-layer-group nav-icon"></i>
                    <p>Liste des DataProduct</p>
                </router-link>
              </li>
            </ul>
          </li>

          <hr/>
          <li class="nav-item menu-open">
            <a href="#" class="nav-link" style="background-color: #3FADE2;">
                <i class="fa-brands fa-blogger-b nav-icon"></i>
              <p>
                DataBloc
                <em class="right fas fa-angle-left"></em>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link :to="{name: 'create-data-bloc'}" class="nav-link">
                    <i class="fa-solid fa-plus nav-icon"></i>
                    <p>Nouveau DataBloc</p>
                </router-link>
              </li>

              <!-- <li class="nav-item">
                <router-link :to="{name: 'list-data-bloc'}" class="nav-link">
                    <i class="fa-solid fa-database nav-icon"></i>
                    <p>Liste des DataBloc</p>
                </router-link>
              </li> -->
            </ul>
          </li>

          <hr/>
          <li class="nav-item menu-open">
            <a href="#" class="nav-link" style="background-color: #3FADE2;">
                <i class="fa-solid fa-user-group"></i>
              <p style="font-family: 'Arial', sans-serif;font-weight: normal;">
                Gestion des utilisateurs
                <em class="right fas fa-angle-left"></em>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link :to="{name: 'list-users'}" class="nav-link">
                    <i class="fa-solid fa-user-shield nav-icon"></i>
                    <p>Liste des utilisateurs</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
  </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
      return{
        user: null
      }
    },

    mounted(){
        this.getCurrentUser();
    },

    methods:{
      async getCurrentUser(){
        const response = await axios.get("admin/profil")
        .then(response=>{
        this.user = response.data.adminData
        })
      }
    },
}
</script>

<style >
    .brand-link{
        background-color: #6f42c1;
    }

    *{
        font-family: 'Arial', sans-serif;
        font-weight: normal;
    }
</style>