<template>
  <div class="wrapper">
    <top-nav/>
    <left-nav/>
    <dashboard/>
    
    <Footer />
  </div>
</template>

<script>
import TopNav from './TopNav.vue';
import LeftNav from './LeftNav.vue';
import Footer from './Footer.vue';
import Dashboard from './pages/Shared/Dashboard.vue';

export default {
    components:{
        TopNav,
        LeftNav,
        Footer,
        Dashboard
    }
}
</script>

<style>

</style>