<template>
  <div>
    <aside class="control-sidebar control-sidebar-dark">
      <div class="p-3">
        <div class="card card-primary card-outline">
          <div class="card-body box-profile">
            <div class="text-center">
              <img
                class="profile-user-img img-fluid img-circle"
                src="../assets/user.png"
                alt="User profile picture"
              />
            </div>

            <div>
              <h3 class="profile-username text-center" style="color: black">
                <span class="text-capitalize">{{ user.first_name }}</span>&nbsp;
                <span class="text-uppercase">{{ user.name }}</span>
              </h3>
              <p class="text-muted text-center">
                {{ user.email }}
              </p>
              <!-- <hr />
              <p class="text-muted text-center">
                Connecté entant que
                
              </p> -->
              <a
                href="javascript:void(0)"
               @click="logout"
                class="btn btn-primary btn-block logout"
                ><strong>Déconnexion</strong></a
              >
            </div>
          </div>
        </div>
      </div>
    </aside>

    <footer class="main-footer">
      <div class="float-right d-none d-sm-inline">Tous droits réservés</div>

      <strong>&copy; {{ new Date().getFullYear() }}
        <a href="#" target="_blank" style="text-decoration: none">DATATRENZ</a>
      </strong>
    </footer>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
      user: [],
    }
  },

  async created() {
    await axios.get("admin/profil")
    .then(res=>{
      this.user = res.data.adminData;
      //console.log(this.user)
    })
    .catch(err=>{console.log(err)})
  },

  methods:{
    logout() {
      window.localStorage.removeItem("token");
      this.$router.push("/");
      window.location.reload();
    },
  }
}
</script>

<style scoped>
  /* .logout {
    background-color: red;
  } */

  .logout:hover {
    background-color: rgb(0, 162, 255);
  }

  .text-muted {
    font-size: 15px;
  }

  *{
    font-family: 'Arial', sans-serif;
    font-weight: normal;
}
</style>