<template>
  <div class="">
    <div class="content-header">
        <div class="container">
          <div class="row mb-2">
            <b-alert show class="bg-jumbotron text-uppercase text-bold" style="background: linear-gradient(70deg, #3A77BC, #3FADE2);;color:#fff;">
              <em :class="faIcon"></em> {{ PageTitle }}
            </b-alert>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ['PageTitle', 'faIcon']
}
</script>

<style scoped>
.content-header{
    font-family: 'Arial', sans-serif;
    font-weight: normal;
}
</style>