<template>
  <div class="wrapper">
    <left-nav />
    <top-nav />

    <div class="content-wrapper">
      <jumbottron
        faIcon="fa-regular fa-newspaper"
        PageTitle="Tous nos articles"
      />

      <div class="content">
        <div class="container-fluid">
          <b-card class="card-primary card-outline">
            <div class="card-header">
              <h6 style="color: #3a77bc; font-weight: bold">Zone de filtre</h6>
            </div>

            <div class="card-body">
              <b-row>
                <div class="form-group" style="width: 45%">
                  <label>Date de début</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="date1"
                    @change="filterDataByDate"
                  />
                </div>

                <div class="form-group" style="width: 45%">
                  <label>Date de fin</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="date2"
                    @change="filterDataByDate"
                  />
                </div>

                <div class="form-group" style="width: 10%">
                  <label for="">Réinitialiser</label>
                  <b-button @click="reinializ()">
                    <i class="fa-solid fa-rotate-right"></i>
                  </b-button>
                </div>
              </b-row>
            </div>
          </b-card>

          <div align="center" v-if="spinner">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem"
              type="grow"
              label="Spinning"
            ></b-spinner>
          </div>

          <div v-if="!spinner">
            <b-card class="card-primary card-outline">
              <div>
                <b-row>
                  <!-- <b-col lg="6" class="my-1">
                    <b-form-group
                      label="Filtre"
                      label-for="sort-by-select"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="md"
                      class="mb-0"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-input-group size="md">
                        <b-form-select
                          id="sort-by-select"
                          v-model="sortBy"
                          :options="sortOptions"
                          :aria-describedby="ariaDescribedby"
                          class="w-75"
                        >
                          <template #first>
                            <option value="">-- Aucun --</option>
                          </template>
                        </b-form-select>

                        <b-form-select
                          v-model="sortDesc"
                          :disabled="!sortBy"
                          :aria-describedby="ariaDescribedby"
                          size="md"
                          class="w-25"
                        >
                          <option :value="false">Asc</option>
                          <option :value="true">Desc</option>
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col> -->

                  <!-- <b-col lg="6" class="my-1">
                    <b-form-group label="Initial sort" label-for="initial-sort-select" label-cols-sm="3"
                            label-align-sm="right" label-size="md" class="mb-0">
                            <b-form-select id="initial-sort-select" v-model="sortDirection" :options="['ASC', 'DESC']"
                            size="md"></b-form-select>
                        </b-form-group>
                  </b-col> -->

                  <b-col lg="6" class="my-1">
                    <b-form-group
                      label="Recherche"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="md"
                      class="mb-0"
                    >
                      <b-input-group size="md">
                        <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Chercher le titre par mot clé"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''"
                            >Effacer</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>

                  <b-col sm="5" md="6" class="my-1">
                    <b-form-group
                      label="Par page"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="md"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="md"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-card>

            <b-card class="card-primary card-outline">
              <b-row>
                <b-col sm="5" md="6" class="my-1">
                  <strong>Total d'articles: {{ totalRows }}</strong>
                </b-col>

                <b-col sm="7" md="6" class="my-1">
                  <div align="right">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="md"
                      class="my-0"
                    >
                    </b-pagination>
                  </div>
                </b-col>
              </b-row>

              <b-table
                :items="filteredData"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
                bordered
                no-border-collapse
                class="mt-2 purple"
                responsive
              >
                <template #cell(title)="row">
                  <b>
                    {{ row.value }}
                  </b>
                </template>

                <template #cell(created_at)="date">
                  {{ date.value | formatDate }}
                </template>

                <template #cell(status)="statut">
                  <b-badge
                    v-if="statut.value == 'activ' || statut.value == 'active'"
                    pill
                    style="background-color: #3bae4c"
                    >Actif</b-badge
                  >
                  <b-badge
                    v-if="statut.value == 'innactiv'"
                    pill
                    style="background: linear-gradient(70deg, #3a77bc, #3fade2)"
                    >Inactif</b-badge
                  >
                </template>
                <!--  -->
                <template #cell(actions)="row">
                  <div class="btn-group">
                    <b-button
                      v-b-tooltip.hover
                      title="Consulter les détails"
                      @click="goToReadNews(row.item)"
                      class=""
                      variant="primary"
                    >
                      <i class="fa-solid fa-eye"></i>
                    </b-button>

                    <b-button
                      v-b-tooltip.hover
                      title="Supprimer l'article"
                      @click="goToDeleteNews(row.item)"
                      class=""
                      variant="danger"
                    >
                      <i class="fa-solid fa-trash"></i>
                    </b-button>

                    <b-button
                      v-b-tooltip.hover
                      title="Désactiver l'article"
                      @click="goToDisableNews(row.item)"
                      class=""
                      variant="warning"
                    >
                      <i class="fa-solid fa-eye-slash"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <!-- Bloc des modals -->
    <div>
      <b-modal
        id="modal-1"
        :title="modalTitle"
        ref="show-modal-article"
        ok-title="Fermer"
        ok-only
        ok-variant="danger"
        no-close-on-esc
        no-close-on-backdrop
        size="xl"
        scrollable
      >
        <b-card class="card card-primary card-outline">
          <form @submit.prevent="handleUpdate()">
            <div class="form-group">
              <label for="title">Souhaitez-vous modifier le titre ?</label>
              <input
                type="text"
                id="title"
                class="form-control"
                v-model="showArticle.title"
              />
            </div>

            <div class="form-group">
              <label for="desc">Souhaitez-vous modifier la description ?</label>
              <textarea
                id="desc"
                cols="2"
                class="form-control"
                v-model="showArticle.desc"
                rows="2"
              ></textarea>
            </div>

            <div class="form-group">
              <label for="content">Souhaitez-vous modifier le contenu ?</label>
              <ckeditor
                id="content"
                :editor="editor"
                v-model="showArticle.content"
              ></ckeditor>
            </div>

            <div class="form-group">
              <label for="desc"
                >Souhaitez-vous modifier l'image de couverture ?</label
              >
              <input
                type="file"
                @change="onFileSelected"
                class="form-control"
              />

              <p>
                <strong>Image courante</strong> <br />
                <img :src="showArticle.file_url" width="200" alt="" />
              </p>

              <p style="color: red; font-weight: bold; font-size: 12px">
                <i class="fa-solid fa-triangle-exclamation"></i>
                Pour que les modifications soient prises en compte, vous devriez
                uploader à nouveau une image de couverture.
              </p>
            </div>

            <div class="form-group">
              <b-spinner
                v-if="loader"
                variant="primary"
                label="Spinning"
              ></b-spinner>
              <button v-if="!loader" type="submit" class="btn btn-success">
                <i class="fa-solid fa-floppy-disk nav-icon"></i>
                Enregistrer les modifications
              </button>
            </div>
          </form>
        </b-card>
      </b-modal>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import LeftNav from "../../LeftNav.vue";
import TopNav from "../../TopNav.vue";
import Footer from "../../Footer.vue";
import Jumbottron from "../Shared/Jumbottron.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";

export default {
  components: {
    Jumbottron,
    LeftNav,
    TopNav,
    Footer,
  },

  data() {
    return {
      spinner: false,
      editor: ClassicEditor,
      loader: false,

      fields: [
        {
          key: "title",
          label: "Titre de l'article",
          sortable: true,
          sortDirection: "desc",
          class: "p-3",
        },
        // {
        //   key: "description",
        //   label: "Description",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "p-3",
        // },
        {
          key: "created_at",
          label: "Publié le",
          sortable: true,
          sortDirection: "desc",
          class: "p-3",
        },
        {
          key: "status",
          label: "Statut",
          sortable: true,
          class: "text-center p-3",
        },
        { key: "actions", label: "Actions", class: "text-center p-3" },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Voir +" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      infoModal: "",
      allNews: [],

      modalTitle: null,
      showArticle: {
        id: null,
        title: null,
        desc: null,
        content: null,
        status: null,
        file: null,
        file_url: null,
      },

      date1: "", // Première date sélectionnée
      date2: "", // Deuxième date sélectionnée
      // apiData: [],     // Données renvoyées par l'API
      filteredData: [], // Données filtrées par les deux dates
    };
  },

  computed: {
    sortOptions() {
      // Filtrer uniquement la colonne avec la key 'title'
      return this.fields
        .filter((f) => f.sortable && f.key === "title") // Filtrer pour la clé 'title'
        .map((f) => {
          return { text: "titre de article", value: f.key }; // Ajuster le label
        });
    },
  },

  mounted() {
    this.getNews();
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    filterDataByDate() {
      if (this.date1 && this.date2) {
        const startDate = new Date(this.date1);
        const endDate = new Date(this.date2);
        // Mettre endDate à la fin de la journée
        endDate.setHours(23, 59, 59, 999);

        this.filteredData = this.allNews.filter((item) => {
          const itemDate = new Date(item.created_at);
          return itemDate >= startDate && itemDate <= endDate;
        });
      } else {
        this.filteredData = this.allNews; // Si les deux dates ne sont pas sélectionnées, afficher toutes les données
      }
      this.totalRows = this.filteredData.length;
    },

    reinializ() {
      this.date1 = "";
      this.date2 = "";
      this.getNews();
    },

    async getNews() {
      this.spinner = true;
      await axios.get("news").then((res) => {
        this.spinner = false;
        this.allNews = res.data.results;
        this.filteredData = this.allNews; // Initialiser avec toutes les données
        this.totalRows = this.filteredData.length;
      });
    },

    goToReadNews(data) {
      this.$refs["show-modal-article"].show();
      this.modalTitle = data.title;

      this.showArticle.id = data.id;
      this.showArticle.title = data.title;
      this.showArticle.desc = data.description;
      this.showArticle.content = data.content;
      this.showArticle.status = data.status;
      this.showArticle.file = data.cover_image;
      this.showArticle.file_url = data.cover_image_url;

      // console.log(data.cover_image)
    },

    goToDeleteNews(data) {
      Swal.fire({
        title: "Etes-vous sûrs ?",
        text: "Vous êtes sur le point de supprimer une donnée",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3BAE4C",
        confirmButtonText: "Oui, continuer",
        cancelButtonColor: "#d33",
        cancelButtonText: "Non, annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`news/${data.id}`)
            .then(() => {
              Swal.fire({
                title: "Supprimé !",
                text: "Suppression effectuée avec succès",
                icon: "success",
              });
              this.getNews();
            })
            .catch(() => {
              Swal.fire({
                title: "Erreur",
                text: "Une erreur s'est produite, réessayer",
                icon: "error",
              });
            });
        }
      });
    },

    onFileSelected(e) {
      this.showArticle.file = e.target.files[0];
    },

    async handleUpdate() {
      this.loader = true;
      let Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      if (
        !this.showArticle.title ||
        !this.showArticle.desc ||
        !this.showArticle.content ||
        !this.showArticle.file
      ) {
        Toast.fire({
          icon: "error",
          title: "Tous les champs sont requis",
        });
        this.loader = false;
      } else {
        const fd = new FormData();

        fd.append("file", this.showArticle.file);
        fd.append("title", this.showArticle.title);
        fd.append("description", this.showArticle.desc);
        fd.append("content", this.showArticle.content);
        fd.append("status", this.showArticle.status);

        await axios
          .patch(`news/${this.showArticle.id}`, fd)
          .then(() => {
            Toast.fire({
              icon: "success",
              title: "Modifications enregistrées avec succes",
            });
            this.$refs["show-modal-article"].hide();
            this.getNews();
            this.loader = false;
          })
          .catch(() => {
            Toast.fire({
              icon: "error",
              title:
                "Erreur ! Rassurez-vous qu'une image ait été uploadé à nouveau",
            });
            this.loader = false;
          });
      }
    },

    goToDisableNews(data) {
      Swal.fire({
        title: "Etes-vous sûrs ?",
        text: "Vous êtes sur le point de modifier le statut de cette ligne",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3BAE4C",
        confirmButtonText: "Oui, continuer",
        cancelButtonColor: "#d33",
        cancelButtonText: "Non, annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          if (data.status == "activ") {
            axios
              .get(`status_update/news/${data.id}/innactiv`)
              .then(() => {
                Swal.fire({
                  title: "Bravo !",
                  text: "Statut désactivé avec succès",
                  icon: "success",
                });
                this.getNews();
              })
              .catch(() => {
                Swal.fire({
                  title: "Erreur",
                  text: "Une erreur s'est produite, réessayer",
                  icon: "error",
                });
              });
          }
          if (data.status == "innactiv") {
            axios
              .get(`status_update/news/${data.id}/activ`)
              .then(() => {
                Swal.fire({
                  title: "Bravo !",
                  text: "Statut activé avec succès",
                  icon: "success",
                });
                this.getNews();
              })
              .catch(() => {
                Swal.fire({
                  title: "Erreur",
                  text: "Une erreur s'est produite, réessayer",
                  icon: "error",
                });
              });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  font-family: "Inter", sans-serif;
  font-weight: normal;
}
</style>