<template>
  <div class="content-wrapper">

    <div class="content">
      <div class="container-fluid">
        <div class="row py-4 d-flext justify-content-center align-items-center">
            <div class="">
            <b-alert show class="bg-jumbotron" style="background: linear-gradient(70deg, #3FADE2, #3A77BC);;color:#fff;">
                <i class="fa-solid fa-user"></i> Bienvenue, {{ user.first_name }} {{ user.name }}. Vous êtes connecté.e en tant <span v-if="user.role == 'admin'">qu'administrateur</span> <span v-else>que {{ user.role }}</span>
            </b-alert>
          </div>
            <!-- <div class="background-container body">
                <div class="text-overlay">
                    <h2>Trouvez les données <br> dont vous avez besoin</h2>
                    <p>Des données en continu dans des domaines divers</p>
                </div>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import Jumbottron from "./Jumbottron.vue";

export default {
  name: "Dashboard1",
  components: {
    // Jumbottron,
  },

  data() {
    return {
      user: null,
      swal: null,
      
    };
  },

  methods: {
   
  },

  async mounted() {
    const response = await axios.get("admin/profil");
    this.user = response.data.adminData;
    // console.log(this.user)
  },

  
};
</script>

<style scoped>
.bg-info-perso {
  background-color: #006699;
}

.content-wrapper{
    font-family: 'Arial', sans-serif;
    font-weight: normal;
}

.body{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    /* font-family: Arial, sans-serif;
    font-weight: normal;  */
}

.background-container {
    position: relative;
    height: 60vh; /* Hauteur de la fenêtre */
    width: 100%;
    background-image: linear-gradient(transparent, #3A77BC),  url('../../../assets/bg_graph.png'); /* Chemin vers votre image */
    background-size: cover; /* Couvre toute la zone */
    background-position: center; /* Centre l'image */
}

.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* Couleur du texte */
    text-align: center;
}

h2 {
    margin: 0;
    font-size: 35pt;
    line-height: 95%;
    margin-bottom: 10px;
    font-weight: bold;
    opacity: 1;
    transform: none;
}

p {
    margin: 0.5em 0 0;
    font-size: 1.2em;
}
</style>