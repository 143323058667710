<template>
  <div class="body">  
  <section>
    <div class="imgBox"></div>
        <div class="contentBox">
            <div class="formBox">
                <div class="login-logo mb-4" align="center">
                  <a href="#">
                    <img src="../../../assets/logo_data.jpeg" width="350" alt="" />
                  </a>
                </div>
                <h2 class="">Se connecter</h2>

                <form @submit.prevent="login()">
                    <div class="inputBx" v-if="step == 0">
                        <span>Email</span>
                        <input type="text" v-model="username" autofocus placeholder="Entrez votre adresse mail">
                    </div>

                    <div class="inputBx" v-if="step == 1">
                        <span>Code OTP</span>
                        <input type="text" v-model="otp" autofocus placeholder="Veuillez entrer votre code OTP ici">
                    </div>

                    <div class="inputBx">
                        <input type="submit" v-if="!loading && step == 0" value="Valider">
                        <input type="submit" v-if="!loading && step == 1" value="Connexion">

                        <b-button variant="warning" disabled  v-show="loading">
                            <b-spinner small type="grow"></b-spinner>
                            Vérification des identifiants encours...
                        </b-button>
                    </div>

                    <div class="inputBx" v-if="step == 1">
                        <p>Vous n'avez pas reçu de code OTP ? Veuillez <a href="#" @click.prevent="reloadOTP()"><strong>cliquer ici</strong></a></p>
                    </div>
                    <!-- <div class="inputBx" v-if="step == 0">
                        <p style="font-family: 'Arial regular'">Veuillez contacter un administrateur si vous n'avez pas de compte</p>
                    </div> -->
                </form>
            </div>
        </div>
  </section>
  </div>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2';

export default {
  name: "Login",
  data() {
    return {
      username: "",
      otp: "",
      error: "",
      loading: false,
      step: 0,

      admin:{
        name: null,
        firstname: null,
        email: null
      },

      date : new Date().toLocaleDateString()
    };
  },

  methods: {
    async login() {
        this.loading = true

        let Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
        });

        if(this.step == 0){
            if (this.username == "") {
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops ...',
                    text: 'Adresse mail obligatoire'
                })
                this.loading = false
            }
            else{
                if(this.username){
                    this.loading = true
                    await axios.post('admin/login', {
                        email: this.username
                    })
                    .then(()=>{
                        Toast.fire({
                            icon: "success",
                            title: `Un code OTP a été envoyé à ${this.username}`,
                        });
                        this.loading = false
                        this.step++
                    })
                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Ooops ...',
                        text: 'Adresse mail non reconnue'
                    })
                    this.loading = false
                }
            }
        }
        else if (this.step == 1) {
        if(!this.otp){
            Swal.fire({
                icon: 'error',
                title: 'Ooops ...',
                text: 'Un code OTP est attendu svp !'
            })
            this.loading = false
        }
        else{
            if(this.otp){
                this.loading = true
                await axios.post("admin/login/verify", {
                    email: this.username,
                    otp: this.otp
                })
                .then(response=>{
                    this.loading = false
                    this.$store.commit("setAuthentication", true);

                    localStorage.setItem("token", response.data.adminData.access_token);
                    
                    if(this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect)
                    }else{
                        this.$router.push({name: "dashboard1"})
                    }
                    /*if(this.date > "22/09/2024"){
                        window.localStorage.removeItem("token");
                        this.$router.push({name: "obsoleteApp"})
                    }*/
                    window.location.reload();
                })
                .catch((err)=>{
                    Swal.fire({
                        icon: 'error',
                        title: 'Ooops ... Accès réfusé',
                        text: 'Ce code OTP est incorrect',
                    })
                    this.loading = false
                })
            }
        }
      }
    },

    async reloadOTP(){
        await axios.post('admin/login', {
            email: this.username
        })
        .then(()=>{
            Swal.fire({
                icon: 'success',
                title: 'Consultez vos mails',
                text: `Un code OTP a été renvoyé à ${this.username}`,
            })
        })
    }
  },
};
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Arial" sans-serif;
    font-weight: normal;
}

section{
    position: relative;
    display: flex;
    height: 100vh;
    width: 100%;

}

section .imgBox{
    position: relative;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(transparent, #3A77BC), url("../../../assets/Market-Research.jpg"); */
    background: url("../../../assets/Market-Research.jpg");
    background-position: center;
    background-size: cover;
}

section .contentBox{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    background-color: #FFF;
}

section .contentBox .formBox h2{
    color: #3FADE2;
    font-weight: 600;
    font-size: 1.5em;
    text-transform: uppercase;
    margin-bottom: 20px;
    /*border-bottom: 4px solid #3A77BC;*/
    text-align: center;
    letter-spacing: 1px;
    display: inline-block;
}

section .contentBox .formBox{
    box-shadow: 21px 10px 15px -3px rgba(0,0,0,0.1),-18px 10px 15px -3px rgba(0,0,0,0.1);
    padding: 10px;
}

.inputBx{
    margin-bottom: 20px;
}

.inputBx span{
    font-size: 16px;
    margin-bottom: 5px;
    display: inline-block;
    color:#607D8B;
    font-weight: 300;
    letter-spacing: 1px;
}

.inputBx input{
    width: 100%;
    padding: 10px 20px;
    font-weight: 400;
    border: 1px solid #607D8B;
    letter-spacing: 1px;
    color: #607D8B;
    outline: none;
    background-color: transparent;
    border-radius: 10px;
}

.inputBx input[type="submit"]{
    background: linear-gradient(70deg, #3A77BC, #3FADE2);;
    color: #fff;
    border: 0;
    outline: 0;
    font-weight: 600;
    cursor: pointer;
}

.inputBx input[type="submit"]:hover{
    background-color: #3BAE4C;
}

.remember{
    margin-bottom: 10px;
    color: #607D8B;
    font-weight: 400;
    font-size: 14px;
}

.inputBx p{
    color: #607D8B;
    text-align: center;
}

@media(max-width:768px){
    section .imgBox{
        position: absolute;
        left:0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    section .contentBox{
        width: 100%;
        z-index: 1;

    }

    section .contentBox .formBox{
        width: 100%;
        background-color: rgb(255 255 255/0.9);
        padding: 40px;
    }
}
</style>