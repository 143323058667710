<template>
    <div class="wrapper">
      <left-nav />
      <top-nav />
  
      <div class="content-wrapper">
        <jumbottron
          faIcon="fa-solid fa-user-shield"
          PageTitle="Liste des utilisateurs"
        />
  
        <div class="content">
          <div class="container-fluid">
            <div align="center" v-if="spinner">
              <b-spinner
                variant="primary"
                style="width: 3rem; height: 3rem"
                type="grow"
                label="Spinning"
              ></b-spinner>
            </div>
  
            <div v-if="!spinner">
              <b-card class="card-primary card-outline">
                <div>
                  <b-row>
                    <!-- <b-col lg="6" class="my-1">
                      <b-form-group
                        label="Filtre"
                        label-for="sort-by-select"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="md"
                        class="mb-0"
                        v-slot="{ ariaDescribedby }"
                      >
                        <b-input-group size="md">
                          <b-form-select
                            id="sort-by-select"
                            v-model="sortBy"
                            :options="sortOptions"
                            :aria-describedby="ariaDescribedby"
                            class="w-75"
                          >
                            <template #first>
                              <option value="">-- Aucun --</option>
                            </template>
                          </b-form-select>
  
                          <b-form-select
                            v-model="sortDesc"
                            :disabled="!sortBy"
                            :aria-describedby="ariaDescribedby"
                            size="md"
                            class="w-25"
                          >
                            <option :value="false">Asc</option>
                            <option :value="true">Desc</option>
                          </b-form-select>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
  
                    <b-col lg="6" class="my-1">
                      <b-form-group label="Initial sort" label-for="initial-sort-select" label-cols-sm="3"
                              label-align-sm="right" label-size="md" class="mb-0">
                              <b-form-select id="initial-sort-select" v-model="sortDirection" :options="['ASC', 'DESC']"
                              size="md"></b-form-select>
                          </b-form-group>
                    </b-col> -->
  
                    <b-col lg="6" class="my-1">
                      <b-form-group
                        label="Recherche"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="md"
                        class="mb-0"
                      >
                        <b-input-group size="md">
                          <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Chercher par nom, prenom ou email"
                          ></b-form-input>
  
                          <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''"
                              >Effacer</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
  
                    <b-col sm="5" md="6" class="my-1">
                      <b-form-group
                        label="Par page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="md"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          size="md"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
  
              <b-card class="card-primary card-outline">
                <b-row>
                  <b-col sm="4" md="4" class="my-1">
                    <strong>Total d'utilisateurs: {{ totalRows }}</strong>
                  </b-col>

                  <b-col sm="4" md="4" class="my-1">
                    <!-- <download-excel
                        :data="allUsers"
                        class="btn"
                        worksheet="data-trenz-utilisateurs"
                        name="liste-des-utilisateurs.xls"
                        style="background-color: #3BAE4C;color:#FFF"
                    >
                    <i class="far fa-file-excel"></i> Exporter la liste
                    </download-excel> -->

                    <b-button @click="exportToExcel" style="background-color: #3BAE4C;color:#FFF">
                        <i class="far fa-file-excel"></i>
                        Exporter en Excel
                    </b-button>
                  </b-col>
  
                  <b-col sm="4" md="4" class="my-1">
                    <div align="right">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="md"
                        class="my-0"
                      >
                      </b-pagination>
                    </div>
                  </b-col>
                </b-row>
  
                <b-table
                  :items="allUsers"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  stacked="md"
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  no-border-collapse
                  class="mt-2 purple"
                  responsive
                  ref="table"
                >
                  <template #cell(name)="row">
                    <b>
                      {{ row.value }}
                    </b>
                  </template>
  
                  <template #cell(created_at)="date">
                    {{ date.value | formatDate }}
                  </template>

                  <template #cell(status)="status">
                    <!-- {{ status.value }} -->
                    <b-badge v-if="status.value == 'confirmed'" pill style="background-color: #3BAE4C;">Confirmé</b-badge>
                    <b-badge v-if="status.value == 'banned'" pill style="background: linear-gradient(70deg, #3A77BC, #3FADE2);">Banni</b-badge>
                  </template>
                  <!--  -->
                  <template #cell(actions)="row">
                    <div class="btn-group">
                      <b-button
                        v-b-tooltip.hover
                        title="Autres les détails"
                        @click="goToGetUser(row.item)"
                        class=""
                        variant="primary"
                      >
                        <i class="fa-solid fa-eye"></i>
                      </b-button>

                        <b-button
                            v-b-tooltip.hover
                            title="Désactiver l'utilisateur"
                            @click="goToDisableUser(row.item)"
                            class=""
                            variant="warning"
                            >
                            <i class="fa-solid fa-eye-slash"></i>
                        </b-button>
                    </div>
                  </template>
                </b-table>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <!-- Bloc des modals -->
      <div>
          <b-modal 
              id="modal-1" 
              :title="modalTitle"
              ref="show-modal-more-info"
              ok-title="Fermer"
              ok-only
              ok-variant="danger"
              no-close-on-esc
              no-close-on-backdrop
              size="xl"
              >
              <b-card class="card card-primary card-outline">
                  <p v-for="(company, index) in companyName" :key="index">
                    <img src="../../../assets/entreprise.png" width="50" style="float: left;padding-right: 2px" alt="">
                    <span><strong>Entreprise:</strong> {{ company.name }}</span> <br/> 
                    <span><a :href="company.web_site" target="_blanc" style="text-decoration: none"><strong>Visiter le site web</strong></a></span>
                  </p>

                  <table class="table table-bordered table-striped table-hover">
                    <thead>
                        <td>{{ role }}</td>
                        <td>{{ description }}</td>
                    </thead>
                  </table>
              </b-card>
          </b-modal>
      </div>
      <Footer />
    </div>
  </template>
  
  <script>
    import axios from "axios";
    import LeftNav from "../../LeftNav.vue";
    import TopNav from "../../TopNav.vue";
    import Footer from "../../Footer.vue";
    import Jumbottron from "../Shared/Jumbottron.vue";
    import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
    import Swal from "sweetalert2";

    import { saveAs } from 'file-saver';
    import * as XLSX from 'xlsx';
  
  export default {
    components: {
      Jumbottron,
      LeftNav,
      TopNav,
      Footer,
    },
  
    data() {
      return {
        spinner: false,
        editor: ClassicEditor,
        loader: false,
  
        fields: [
          {
            key: "name",
            label: "Noms",
            sortable: true,
            sortDirection: "desc",
            class: "p-3",
          },
           {
             key: "firstname",
             label: "Prénoms",
             sortable: true,
             sortDirection: "desc",
             class: "p-3",
          },
          {
            key: "email",
            label: "Email",
            sortable: true,
            sortDirection: "desc",
            class: "p-3",
          },
          {
            key: "status",
            label: "Statut",
            sortable: true,
            class: "text-center p-3",
          },
          { key: "actions", label: "Actions", class: "text-center p-3" },
        ],
        totalRows: 0,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Voir +" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
  
        infoModal: "",
        allUsers: [],
  
        modalTitle: null,
        
        companyName: [],
        webSite: null,
        role: null,
        description: null
      };
    },
  
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
    },
  
    mounted() {
      this.getUsers();
    },
  
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
  
      async getUsers() {
        this.spinner = true;
        await axios.get("users").then((res) => {
          this.spinner = false;
          this.allUsers = res.data.results;
          this.totalRows = this.allUsers.length;

          // console.log(res.data)
        });
      },
  
      onFileSelected(e) {
          this.showArticle.file = e.target.files[0];
      },
  
      goToGetUser(item){
        this.companyName = item.Companies,
        this.webSite = item.companies,
        this.role = item.DataScientistRole.name,
        this.description = item.DataScientistRole.description


        this.$refs['show-modal-more-info'].show()
        this.modalTitle = `Autres informations concernant ${item.name} ${item.firstname}`

       // console.log(item.Companies) 
      },

      async goToDisableUser(data){
    // console.log(data)
        Swal.fire({
            title: "Etes-vous sûrs ?",
            text: "Le statut de cette ligne sera modifié",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3BAE4C",
            confirmButtonText: "Oui, continuer",
            cancelButtonColor: "#d33",
            cancelButtonText: "Non, annuler"
            }).then((result) => {
            if (result.isConfirmed) {
                if(data.status == "confirmed"){
                    axios.get(`status_update/user/${data.id}/banned`)
                    .then(()=>{
                        Swal.fire({
                            title: "Bravo !",
                            text: "Statut utilisateur désactivé avec succès",
                            icon: "success"
                        });
                        this.getUsers()
                    })
                    .catch((err)=>{
                        Swal.fire({
                            title: "Erreur",
                            text: "Une erreur s'est produite, réessayer",
                            icon: "error"
                        });
                        console.log(err)
                    })
                }
                if(data.status == "banned"){
                    axios.get(`status_update/user/${data.id}/confirmed`)
                    .then(()=>{
                        Swal.fire({
                            title: "Bravo !",
                            text: "Statut utilisateur activé avec succès",
                            icon: "success"
                        });
                        this.getUsers()
                    })
                    .catch((err)=>{
                        Swal.fire({
                            title: "Erreur",
                            text: "Une erreur s'est produite, réessayer",
                            icon: "error"
                        });
                        console.log(err)
                        
                    })
                }
            }
        });
        },

        exportToExcel() {
      // Préparer les données à exporter
      const dataToExport = this.allUsers.map((item, index) => ({
        nom: item.name,
        prenom: item.firstname,
        email: item.email,
        phone: item.mobile_number,
        // role: item.DataScientistRole.name,
        // entreprise: item.Companies[index].name,
        // site_web: item.Companies[index].web_site
      }));

      // Convertir les données en feuille de calcul
      const ws = XLSX.utils.json_to_sheet(dataToExport);

      // Créer un nouveau classeur et ajouter la feuille
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Générer un fichier binaire
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      // Enregistrer le fichier
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'datatrenz-users.xlsx');
    },
    },
  };
  </script>

  <style scoped>
  .wrapper{
    font-family: 'Arial', sans-serif;
    font-weight: normal;
}
  </style>