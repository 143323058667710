import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false


window.token = localStorage.getItem('token');
// Effacer le localStorage à la fermeture du navigateur ou de l'onglet
// window.onbeforeunload = () => {
//   localStorage.removeItem('token'); new Date().toLocaleDateString()
// }
import "./axios/index";

import "admin-lte/plugins/jquery/jquery"
import "admin-lte/plugins/bootstrap/js/bootstrap.bundle"
import "admin-lte/dist/js/adminlte"

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

import CKEditor from '@ckeditor/ckeditor5-vue2';

import JsonExcel from "vue-json-excel"
Vue.component("downloadExcel", JsonExcel)

import moment from 'moment';
// Formatage de date en utilisant la librairie Moment
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

// if()

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use( CKEditor )

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')