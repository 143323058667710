<template>
  <div class="wrapper">
    <left-nav />
    <top-nav />

    <div class="content-wrapper">
      <jumbottron
        faIcon="fa-solid fa-layer-group"
        PageTitle="Toutes nos dataProducts"
      />

      <div class="content">
        <div class="container-fluid">
            <b-card class="card-primary card-outline">
              <div class="card-header">
                <h6 style="color: #3A77BC; font-weight: bold;">Zone de filtre</h6>
              </div>

              <div class="card-body">
                <b-row>
                    <div class="form-group" style="width: 45%">
                        <label>Date de début</label>
                        <input type="date" class="form-control" v-model="date1" @change="filterDataByDate">
                    </div>

                    <div class="form-group" style="width: 45%">
                        <label>Date de fin</label>
                        <input type="date" class="form-control" v-model="date2" @change="filterDataByDate">
                    </div>

                    <div class="form-group" style="width: 10%">
                        <label for="">Réinitialiser</label>
                        <b-button @click="reinializ()">
                            <i class="fa-solid fa-rotate-right"></i>
                        </b-button>
                    </div>
                </b-row>
              </div>
            </b-card>

          <div align="center" v-if="spinner">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem"
              type="grow"
              label="Spinning"
            ></b-spinner>
          </div>

          <div v-if="!spinner">
            <b-card class="card-primary card-outline">
              <div>
                <b-row>
                  <!-- <b-col lg="6" class="my-1">
                    <b-form-group
                      label="Trier"
                      label-for="sort-by-select"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="md"
                      class="mb-0"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-input-group size="md">
                        <b-form-select
                          id="sort-by-select"
                          v-model="sortBy"
                          :options="sortOptions"
                          :aria-describedby="ariaDescribedby"
                          class="w-75"
                        >
                          <template #first>
                            <option value="">-- Aucun --</option>
                          </template>
                        </b-form-select>

                        <b-form-select
                          v-model="sortDesc"
                          :disabled="!sortBy"
                          :aria-describedby="ariaDescribedby"
                          size="md"
                          class="w-25"
                        >
                          <option :value="false">Asc</option>
                          <option :value="true">Desc</option>
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6" class="my-1"> </b-col> -->

                  <b-col lg="6" class="my-1">
                    <b-form-group
                      label="Rechercher"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="md"
                      class="mb-0"
                    >
                      <b-input-group size="md">
                        <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Chercher le Libellé ou la catégorie par mot clé"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''"
                            >Effacer</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>

                  <b-col sm="5" md="6" class="my-1">
                    <b-form-group
                      label="Par page"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="md"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="md"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-card>

            <b-card class="card-primary card-outline">
              <b-row>
                <b-col sm="5" md="6" class="my-1">
                  <strong>Total de dataProducts: {{ totalRows }}</strong>
                </b-col>

                <b-col sm="7" md="6" class="my-1">
                  <div align="right">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="md"
                      class="my-0"
                    >
                    </b-pagination>
                  </div>
                </b-col>
              </b-row>

              <b-table
                :items="filteredData"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
                bordered
                no-border-collapse
                class="mt-2 purple"
                responsive
              >
                <template #cell(name)="row">
                  <b>{{ row.value }}</b>
                </template>

                <template #cell(created_at)="date">
                  {{ date.value | formatDate }}
                </template>

                <template #cell(status)="status">
                  <b-badge
                    v-if="
                      status.value == 'innactiv' || status.value == 'inactive'
                    "
                    pill
                    style="background: linear-gradient(70deg, #3a77bc, #3fade2)"
                  >
                    Inactif
                  </b-badge>

                  <b-badge
                    v-if="status.value == 'activ'"
                    pill
                    style="background-color: #3bae4c"
                  >
                    Actif
                  </b-badge>
                </template>

                <template #cell(actions)="row">
                  <div class="btn-group">
                    <b-button
                      v-b-tooltip.hover
                      title="Consulter les détails"
                      @click="goToReadNews(row.item)"
                      class=""
                      variant="primary"
                    >
                      <i class="fa-solid fa-eye"></i>
                    </b-button>

                    <b-button
                      v-b-tooltip.hover
                      title="Supprimer la dataProduct"
                      @click="goToDeleteDataProduct(row.item)"
                      class=""
                      variant="danger"
                    >
                      <i class="fa-solid fa-trash"></i>
                    </b-button>

                    <b-button v-if="row.item.status == 'activ'"
                      v-b-tooltip.hover
                      title="Désactiver le dataProduct"
                      @click="goToDisableDataProduct(row.item)"
                      class=""
                      variant="warning"
                    >
                      <i class="fa-solid fa-eye-slash"></i>
                    </b-button>

                    <b-button v-if="row.item.status == 'innactiv' || row.item.status == 'inactive'"
                      v-b-tooltip.hover
                      title="Activer le dataProduct"
                      @click="goToDisableDataProduct(row.item)"
                      class=""
                      variant=""
                      style="background-color: #3bae4c"
                    >
                      <i class="fa-solid fa-eye-slash"></i>
                    </b-button>

                    <b-button
                      v-b-tooltip.hover
                      title="Modifier"
                      @click="goToUpdateDataProduct(row.item)"
                      class=""
                      variant="secondary"
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <!-- Bloc des modals -->
    <div>
      <b-modal
        id="modal-1"
        :title="modalTitle"
        ref="show-modal-article"
        ok-title="Fermer"
        ok-only
        ok-variant="danger"
        no-close-on-esc
        no-close-on-backdrop
        size="xl"
        scrollable
      >
        <div align="center" v-if="spinnerProd">
          <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem"
            type="grow"
            label="Spinning"
          ></b-spinner>
        </div>

        <b-card class="card card-primary card-outline" v-if="!spinnerProd">
          <!-- {{ productById.areas.length }} -->
          <div>
            <table
              class="table table-bordered table-collapse table-striped table-hover"
            >
              <thead
                class=""
                style="
                  background: linear-gradient(70deg, #3a77bc, #3fade2);
                  color: #fff;
                "
              >
                <th width="25%">Catégorie</th>
                <th width="75%">Description</th>
              </thead>

              <tbody>
                <td>
                  <span
                    v-for="(category, index) in productById.areas"
                    :key="index"
                    >{{ category.name }}</span
                  >
                </td>
                <td>{{ productById.description }}</td>
              </tbody>
            </table>

            <b-card class="card card-primary card-outline">
              <div v-if="countproductByid > 0">
                <div
                  class="accordion"
                  role="tablist"
                  v-for="(question, index) in productById.dataBlocs"
                  :key="index"
                >
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        block
                        v-b-toggle="`item-${index}`"
                        style="background-color: #3bae4c"
                        >{{ question.title }}</b-button
                      >
                    </b-card-header>

                    <b-collapse
                      :id="`item-${index}`"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>
                          <b-list-group>
                            <b-list-group-item
                              button
                              v-for="(reponse, num) in productById.dataBlocs[
                                index
                              ].values"
                              :key="num"
                            >
                              Réponse {{ num + 1 }} : {{ reponse.label }}
                            </b-list-group-item>
                          </b-list-group>

                          <span class="float-right">
                            <b-badge
                              v-if="
                                question.status == 'active' ||
                                question.status == 'activ'
                              "
                              pill
                              style="background-color: #3bae4c"
                              >Statut actif</b-badge
                            >
                            <b-badge
                              v-if="question.status == 'innactiv'"
                              pill
                              style="
                                background: linear-gradient(
                                  70deg,
                                  #3a77bc,
                                  #3fade2
                                );
                              "
                              >Statut inactif</b-badge
                            >
                            <!-- {{ question.status }} -->
                          </span>
                        </b-card-text>
                      </b-card-body>

                      <div class="card-footer">
                        <div class="btn-bloc">
                          <b-button
                            v-b-tooltip.hover
                            title="Supprimer la dataBloc"
                            @click="goToDeleteDataBloc(question)"
                            class="mr-1"
                            variant="danger"
                          >
                            <i class="fa-solid fa-trash"></i> Supprimer ce
                            dataBloc
                          </b-button>

                          <b-button
                            v-b-tooltip.hover
                            title="Modifier la dataBloc"
                            @click="goToUpdatDataBloc(question)"
                            class=""
                            variant=""
                            style="
                              background: linear-gradient(
                                70deg,
                                #3a77bc,
                                #3fade2
                              );
                              border: none;
                            "
                          >
                            <i class="fa-solid fa-pen-to-square"></i> Modifier
                            ce dataBloc
                          </b-button>

                          <b-button
                            v-b-tooltip.hover
                            title="Modifier le statut de dataBloc"
                            @click="goToUpdatDataBlocStatus(question)"
                            class="float-right"
                            variant="secondary"
                          >
                            <i class="fa-solid fa-pen-to-square"></i> Modifier
                            le statut
                          </b-button>
                        </div>
                      </div>
                    </b-collapse>
                  </b-card>
                </div>
              </div>

              <div v-else>
                <p class="text-center">
                  <strong>Aucune databloc pour le moment !</strong>
                </p>
              </div>
            </b-card>
          </div>
        </b-card>
      </b-modal>

      <b-modal
        id="modal-1"
        :title="updateTitle"
        ref="show-modal-update"
        ok-title="Fermer"
        ok-only
        ok-variant="danger"
        no-close-on-esc
        no-close-on-backdrop
        size="xl"
      >
        <b-card class="card card-primary card-outline" v-if="!spinner">
          <form @submit.prevent="handleUpdate()">
            <div class="form-group">
              <label for="name" class="">Libellé</label>
              <input
                type="text"
                id="name"
                class="form-control"
                v-model="update.name"
              />
            </div>

            <div class="form-group">
              <label for="desc" class="">Description</label>
              <input
                type="text"
                id="desc"
                class="form-control"
                v-model="update.description"
              />
            </div>

            <div class="form-group">
              <label for="name" class="">Catégories</label>
              <p style="color: red; text-transform: lowercase; font-size: 12px">
                <i class="fa-solid fa-triangle-exclamation"></i>
                Afin que les modifications soient prises en compte, merci de
                sélectionner à nouveau une catégorie.
              </p>
              <select class="form-control" multiple v-model="update.categories">
                <option
                  v-for="category in categoriesList"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <b-spinner
                variant="success"
                v-if="loading"
                label="Spinning"
              ></b-spinner>
              <button v-if="!loading" class="btn btn-primary">
                <i class="fa-solid fa-floppy-disk"></i> Enregistrer les
                modifications
              </button>
            </div>
          </form>
        </b-card>
      </b-modal>

      <b-modal
        id="modal-1"
        :title="dataBlocTitle"
        ref="show-modal-update-databloc"
        ok-title="Annuler"
        ok-only
        ok-variant="danger"
        no-close-on-esc
        no-close-on-backdrop
        size="xl"
      >
        <b-card class="card card-primary card-outline" v-if="!spinner">
          <b-form @submit.prevent="handleUpdateDataBloc()">
            <fieldset class="scheduler-border">
              <legend class="scheduler-border">DataBloc</legend>

              <b-form-group>
                <label for="title">Titre *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updateDataBloc.dataBloc.title"
                />
              </b-form-group>

              <b-form-group>
                <label for="">Chart type *</label>
                <select
                  class="form-control"
                  v-model="updateDataBloc.dataBloc.default_chart_type"
                  id=""
                >
                  <option :value="updateDataBloc.default_chart_type">
                    {{ updateDataBloc.default_chart_type }}
                  </option>
                  <option
                    v-for="(chart, item) in chartType"
                    :key="item"
                    :value="chart.value"
                  >
                    {{ chart.text }}
                  </option>
                </select>
              </b-form-group>

              <b-form-group class="">
                <label for="categories">Catégories *</label>
                <p
                  style="color: red; text-transform: lowercase; font-size: 12px"
                >
                  <i class="fa-solid fa-triangle-exclamation"></i>
                  Pour que les modifications soient prises en compte, vous
                  devriez sélectionner minimum une categorie
                </p>
                <!-- <select id="categories" v-model="updateDataBloc.dataBloc.categories_ids" multiple class="form-control">
                        <option v-for="category in categoriesList" :key="category.id" :value="category.id">
                            {{ category.name }}
                        </option>
                    </select> -->

                <select
                  id="categories"
                  v-model="categoriesInput"
                  multiple
                  class="form-control"
                >
                  <option
                    v-for="category in categoriesList"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </b-form-group>

              <!-- DataBlocValue -->
              <fieldset class="scheduler-border">
                <legend class="scheduler-border">DataBlocValue</legend>

                <b-card
                  class="card-primary card-outline"
                  v-for="(value, valueIndex) in updateDataBloc.dataBlocValues"
                  :key="valueIndex"
                >
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label :for="'label-' + valueIndex + '-' + valueIndex"
                          >Réponse {{ valueIndex + 1 }}</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          :id="'label-' + valueIndex + '-' + valueIndex"
                          v-model="value.label"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label
                          :for="'data_value-' + valueIndex + '-' + valueIndex"
                          >Valeur {{ valueIndex + 1 }}</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          :id="'data_value-' + valueIndex + '-' + valueIndex"
                          v-model="value.data_value"
                        />
                      </div>
                    </div>
                  </div>
                </b-card>

                <div class="form-group">
                  <button
                    type="button"
                    @click="addDataBlocValue()"
                    class="btn btn-secondary"
                  >
                    <i class="fa-solid fa-plus"></i> Ajouter une dataBlocValue
                  </button>

                  <!-- <button type="button" @click="removeDataBlocValue(valueIndex)" class="btn btn-warning float-right">
                            <i class="fa-solid fa-eraser"></i> Retirer une dataBlocValue
                        </button> -->
                </div>
              </fieldset>
            </fieldset>

            <div class="form-group float-right mt-2">
              <b-spinner
                variant="success"
                v-if="loading"
                label="Spinning"
              ></b-spinner>
              <button class="btn btn-primary" v-if="!loading">
                Sauvegarder les modifs
              </button>
            </div>
          </b-form>
        </b-card>
      </b-modal>
    </div>
    <Footer />
  </div>
</template>
  
  <script>
import axios from "axios";
import LeftNav from "../../LeftNav.vue";
import TopNav from "../../TopNav.vue";
import Footer from "../../Footer.vue";
import Jumbottron from "../Shared/Jumbottron.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";

export default {
  components: {
    Jumbottron,
    LeftNav,
    TopNav,
    Footer,
  },

  data() {
    return {
      spinner: false,
      editor: ClassicEditor,
      loader: false,
      spinnerProd: false,

      fields: [
        {
          key: "name",
          label: "Libellé de l'étude",
          sortable: true,
          sortDirection: "desc",
          class: "p-3",
        },
        {
          key: "areas[0].name",
          label: "Catégorie",
          sortable: true,
          sortDirection: "desc",
          class: "p-3",
        },
        {
          key: "created_at",
          label: "Publié le",
          sortable: true,
          sortDirection: "desc",
          class: "p-3",
        },
        {
          key: "status",
          label: "Statut",
          sortable: true,
          class: "text-center p-3",
        },
        { key: "actions", label: "Actions", class: "text-center p-3" },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Voir +" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      infoModal: "",
      allProducts: [],

      modalTitle: null,
      product: {
        id: null,
      },
      productById: [],
      countproductByid: 0,
      updateTitle: null,
      categoriesList: [],

      update: {
        id: "",
        name: "",
        description: "",
        categories: "",
      },
      currentCategory: "",
      loading: false,

      dataBlocTitle: null,

      updateDataBloc: {
        dataBloc: {
          id: "",
          data_product_id: "",
          categories_ids: "[]",
          title: "",
          default_chart_type: "",
          status: "",
        },
        dataBlocValues: [
          {
            label: "",
            data_value: "",
            status: "active",
          },
        ],
      },
      categoriesInput: "",

      chartType: [
        { value: "pie", text: "Pie" },
        { value: "line", text: "Line" },
        { value: "doughnut", text: "Doughnut" },
        { value: "bar", text: "Bar" },
      ],

    //   yearsList: [],
    //   maxYear: new Date().getFullYear(),
    //   minYear: 2022,

    date1: '',       // Première date sélectionnée
    date2: '',       // Deuxième date sélectionnée
    // apiData: [],     // Données renvoyées par l'API
    filteredData: [] // Données filtrées par les deux dates
    };
  },

  computed: {
    normalizeString(str) {
        return str
        .normalize("NFD") // Sépare les caractères et les accents
        .replace(/[\u0300-\u036f]/g, "") // Retire les accents
        .replace(/[\W_]+/g, "") // Retire les caractères non-alphanumériques (espaces, ponctuation, etc.)
        .toLowerCase(); // Convertit en minuscules
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },

  mounted() {
    this.getProducts();
    this.getCategorie();
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getCategorie() {
      await axios.get("setup").then((res) => {
        this.categoriesList = res.data.activity_areas;
      });
    },

    filterDataByDate() {
        if (this.date1 && this.date2) {
        const startDate = new Date(this.date1);
        const endDate = new Date(this.date2);    
        // Mettre endDate à la fin de la journée
        endDate.setHours(23, 59, 59, 999);    

        this.filteredData = this.allProducts.filter(item => {
            const itemDate = new Date(item.created_at);
            return itemDate >= startDate && itemDate <= endDate;
        });
        } else {
            this.filteredData = this.allProducts; // Si les deux dates ne sont pas sélectionnées, afficher toutes les données
        }
        this.totalRows = this.filteredData.length;
    },

    reinializ(){
        this.date1 = ''
        this.date2 = ''
        this.getProducts()
    },

    async getProducts() {
      this.spinner = true;
      await axios.get("data_product").then((res) => {
        this.spinner = false;
        this.allProducts = res.data.results;

        this.filteredData = this.allProducts; // Initialiser avec toutes les données
        this.totalRows = this.filteredData.length;
      });
    },

    goToDeleteDataProduct(data) {
      Swal.fire({
        title: "Etes-vous sûrs ?",
        text: "Vous êtes sur le point de supprimer une donnée",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3BAE4C",
        confirmButtonText: "Oui, continuer",
        cancelButtonColor: "#d33",
        cancelButtonText: "Non, annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`data_product/${data.id}`)
            .then(() => {
              Swal.fire({
                title: "Supprimé !",
                text: "Suppression effectuée avec succès",
                icon: "success",
              });
              this.getProducts();
            })
            .catch(() => {
              Swal.fire({
                title: "Erreur",
                text: "Une erreur s'est produite, réessayer",
                icon: "error",
              });
            });
        }
      });
    },

    goToDeleteDataBloc(databloc) {
      Swal.fire({
        title: "Etes-vous sûrs ?",
        text: "Vous êtes sur le point de supprimer une dataBloc",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3BAE4C",
        confirmButtonText: "Oui, continuer",
        cancelButtonColor: "#d33",
        cancelButtonText: "Non, annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`databloc/${databloc.id}`)
            .then(() => {
              Swal.fire({
                title: "Supprimé !",
                text: "Suppression effectuée avec succès",
                icon: "success",
              });
              axios.get(`data_product/${this.product.id}`).then((res) => {
                this.productById = res.data;
                this.countproductByid = this.productById.length;

                this.spinner = false;
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Erreur",
                text: "Une erreur s'est produite, réessayer",
                icon: "error",
              });
            });
        }
      });
      // console.log()
    },

    goToReadNews(data) {
      this.spinnerProd = true;

      this.$refs["show-modal-article"].show();
      this.modalTitle = data.name.toUpperCase();
      this.product.id = data.id;

      this.getDataBlocById();
    },

    async getDataBlocById() {
      axios.get(`data_product/${this.product.id}`).then((res) => {
        this.productById = res.data;
        this.countproductByid = res.data.dataBlocs.length;
        // console.log(data)
        this.spinnerProd = false;
      });
    },

    goToUpdatDataBlocStatus(data) {
      Swal.fire({
        title: "Etes-vous sûrs ?",
        text: "Le statut de cette ligne sera modifié",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3BAE4C",
        confirmButtonText: "Oui, continuer",
        cancelButtonColor: "#d33",
        cancelButtonText: "Non, annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          if (data.status == "activ" || data.status == "active") {
            axios
              .get(`status_update/dataBloc/${data.id}/innactiv`)
              .then(() => {
                Swal.fire({
                  title: "Bravo !",
                  text: "Statut désactivé avec succès",
                  icon: "success",
                });
                this.getDataBlocById();
              })
              .catch(() => {
                Swal.fire({
                  title: "Erreur",
                  text: "Une erreur s'est produite, réessayer",
                  icon: "error",
                });
              });
          }
          if (data.status == "innactiv") {
            axios
              .get(`status_update/dataBloc/${data.id}/activ`)
              .then(() => {
                Swal.fire({
                  title: "Bravo !",
                  text: "Statut activé avec succès",
                  icon: "success",
                });
                this.getDataBlocById();
              })
              .catch(() => {
                Swal.fire({
                  title: "Erreur",
                  text: "Une erreur s'est produite, réessayer",
                  icon: "error",
                });
              });
          }
        }
      });
    },

    goToUpdateDataProduct(data) {
      this.$refs["show-modal-update"].show();
      this.updateTitle = `Vous modifiez les informations de ${data.name}`;
      this.update.id = data.id;
      this.update.name = data.name;
      this.update.description = data.description;

      for (let i = 0; i <= data.areas.length; i++) {
        this.update.categories = data.areas[i].name;
        this.currentCategory = data.areas[i].name;
      }
    },

    handleUpdate() {
      const payloadToSend = {
        ...this.update,
        categories: this.update.categories.join(", "),
      };
      this.loading = true;
      let Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });

      if (
        !this.update.name ||
        !this.update.description ||
        !this.update.categories
      ) {
        Toast.fire({
          icon: "error",
          title: "Tous les champs sont requis",
        });
        this.loading = false;
      } else {
        Swal.fire({
          title: "Attention",
          text: "Assurez-vous d'avoir sélectionné au moins une categorie avant de poursuivre",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3BAE4C",
          confirmButtonText: "C'est fait ! Continuer",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non. Revenir",
        }).then((result) => {
          if (result.isConfirmed) {
            axios
              .put(`data_product/${this.update.id}`, payloadToSend)
              .then(() => {
                Toast.fire({
                  icon: "success",
                  title: "Cette ligne a été modifiée avec succès",
                });
                this.loading = false;
                this.$refs["show-modal-update"].hide();
                this.getProducts();
              })
              .catch(() => {
                Swal.fire({
                  title: "Erreur",
                  text: "Une erreur s'est produite, réessayer",
                  icon: "error",
                });
              });
          }
        });
      }
    },

    goToUpdatDataBloc(data) {
      this.dataBlocTitle = `Formulaire de modification du dataBloc "${data.title}"`;
      this.$refs["show-modal-update-databloc"].show();

      this.updateDataBloc.dataBloc.id = data.id;
      (this.updateDataBloc.dataBloc.data_product_id = data.data_product_id),
        (this.updateDataBloc.dataBloc.title = data.title);
      this.updateDataBloc.dataBloc.default_chart_type = data.default_chart_type;
      this.updateDataBloc.dataBloc.status = data.status;

      this.updateDataBloc.dataBlocValues = data.values;

      // console.log(this.updateDataBloc.dataBlocValues)
    },

    handleUpdateDataBloc() {
      // const payloadToSend = {
      //     ...this.updateDataBloc,
      //     categories: this.updateDataBloc.dataBloc.categories_ids.join(", "),
      // };
      if (typeof this.categoriesInput === "string") {
        this.updateDataBloc.dataBloc.categories_ids = JSON.stringify(
          this.categoriesInput.split(",").map((id) => parseInt(id.trim()))
        );
      }
      this.loading = true;
      let Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      if (
        !this.updateDataBloc.dataBloc.title ||
        !this.updateDataBloc.dataBloc.default_chart_type
      ) {
        Toast.fire({
          icon: "error",
          title: "Tous les champs sont requis",
        });
        this.loading = false;
      } else {
        axios
          .put(
            `databloc/${this.updateDataBloc.dataBloc.id}`,
            this.updateDataBloc
          )
          .then(() => {
            Toast.fire({
              icon: "success",
              title: "Modifications apportées avec succès",
            });
            this.loading = false;
            this.getDataBlocById();
            this.$refs["show-modal-update-databloc"].hide();
          })
          .catch(() => {
            Toast.fire({
              icon: "error",
              title: "Une erreur est survenue, veuillez réessayer",
            });
            this.loading = false;
          });
      }
    },

    addDataBlocValue() {
      this.updateDataBloc.dataBlocValues.push({
        label: "",
        data_value: "",
        status: "active",
      });
    },

    // removeDataBlocValue(valueIndex){
    //     this.updateDataBloc.dataBlocValues.splice(valueIndex, 1)
    // },

    async goToDisableDataProduct(data) {
      Swal.fire({
        title: "Etes-vous sûrs ?",
        text: "Le statut de cette ligne sera modifié",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3BAE4C",
        confirmButtonText: "Oui. Continuer",
        cancelButtonColor: "#d33",
        cancelButtonText: "Non. Annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          if (data.status == "activ") {
            axios
              .get(`status_update/dataProduct/${data.id}/innactiv`)
              .then(() => {
                Swal.fire({
                  title: "Bravo !",
                  text: "Statut désactivé avec succès",
                  icon: "success",
                });
                this.getProducts();
              })
              .catch(() => {
                Swal.fire({
                  title: "Erreur",
                  text: "Une erreur s'est produite, réessayer",
                  icon: "error",
                });
              });
          }
          if (data.status == "innactiv" || data.status == "inactive") {
            axios
              .get(`status_update/dataProduct/${data.id}/activ`)
              .then(() => {
                Swal.fire({
                  title: "Bravo !",
                  text: "Statut activé avec succès",
                  icon: "success",
                });
                this.getProducts();
              })
              .catch(() => {
                Swal.fire({
                  title: "Erreur",
                  text: "Une erreur s'est produite, réessayer",
                  icon: "error",
                });
              });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
fieldset.scheduler-border {
  border: 2px solid #3a77bc !important;
  padding: 0 10px 10px 10px;
  border-bottom: none;
}

legend.scheduler-border {
  width: auto !important;
  border: none;
  font-size: 14px;
  float: none;
  padding: inherit;
  color: #3bae4c;
  font-weight: bold !important;
}

.content-wrapper{
    font-family: 'Arial', sans-serif;
    font-weight: normal;
}
</style>